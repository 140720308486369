import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Grid, List, ListItem, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import AddAlertIcon from '@mui/icons-material/AddAlert'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'
import ChatIcon from '@mui/icons-material/Chat'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import RouterIcon from '@mui/icons-material/Router'
import SavingsIcon from '@mui/icons-material/Savings'
import { PropsFromReduxHome } from '../containers/HomeContainer'
import { useEffect, useState } from 'react'
import history from '../../../routes/history'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
import { useSelector } from 'react-redux'
import {
  canAddModifyDeleteTransmitter,
  canViewPatientAlert,
  canViewPatientBilling,
  canViewPatientData,
  canViewPatientPrescription,
} from '../../auth/selectors/authSelector'
import ReceiptIcon from '@mui/icons-material/Receipt'

type cardProps = {
  disabled?: boolean
  header: {
    text: string
  }
  row: Array<{
    text: string
    link: string
    content: Array<{ chip: boolean; text: string }> | null
  }>
}

const useStyles = makeStyles(() => ({
  paperCard: {
    borderRadius: '15px !important',
    boackgroundColor: 'grey',
  },
  listCard: { paddingTop: '0 !important', paddingBottom: '0 !important' },
  spanHeaderCard: { marginRight: '5px' },
}))

const HomeComponent = (props: PropsFromReduxHome) => {
  const { homeData } = { ...props }
  const xs = 12
  const sm = 8
  const md = 5
  const lg = 5

  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const fetchDatas = async () => {
    console.log(`is makerAdmin ${isMakerAdmin()}`)
    setLoading(true)
    try {
      if (!isMakerAdmin()) {
        await props.patientExportSummary(props.token)
        await props.patientSummary(props.token)
      }
      await props.transmitterSummary(props.token)
      await props.billingSummary(props.token)
      await props.prescriptionsSummary(props.token)
    } catch (e: any) {
      toast.error('Impossible de charger les données')
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDatas()
  }, []) //eslint-disable-line

  // useEffect(() => {
  //   props.homeData.initStatePatientExportSummary.newAlertNb
  //   props.homeData.initStateTransmitterSummary.disconnect
  //   props.homeData.initStatePatientSummary.active
  //   props.homeData.initStateBillingSummary.lateBilling
  // }, [props.homeData])

  function handleClickItemListCard(link: string): void {
    history.push(link)
  }
  function isMakerAdmin(): boolean {
    return (
      props.user?.roles.findIndex((role: { id: number; roleName: string }) => {
        return role.roleName === 'MakerAdmin'
      }) !== -1
    )
  }

  function rowsPrescription(): any {
    let rowsPrescriptions = []
    if (isMakerAdmin()) {
      rowsPrescriptions.push({
        text: 'Prescriptions à charger :',
        link: 'prescriptions',
        content: [
          {
            chip: true,
            text: `${homeData.initStatePrescriptionSummary.toRead}`,
          },
        ],
      })
      rowsPrescriptions.push({
        text: 'Prescriptions chargées :',
        link: 'prescriptions',
        content: [
          {
            chip: true,
            text: `${homeData.initStatePrescriptionSummary.read}`,
          },
        ],
      })
    } else {
      rowsPrescriptions.push({
        text: 'Prescriptions signées :',
        link: 'prescriptions',
        content: [
          {
            chip: true,
            text: `${homeData.initStatePrescriptionSummary.signed}`,
          },
        ],
      })
    }
    rowsPrescriptions.push({
      text: 'Prescriptions à signer :',
      link: 'prescriptions',
      content: [
        {
          chip: true,
          text: `${homeData.initStatePrescriptionSummary.generated}`,
        },
      ],
    })
    rowsPrescriptions.push({
      text: 'Données manquantes :',
      link: 'prescriptions',
      content: [
        {
          chip: true,
          text: `${homeData.initStatePrescriptionSummary.missingData}`,
        },
      ],
    })

    console.log(`rowsPrescription ${JSON.stringify(rowsPrescriptions)}`)
    return rowsPrescriptions
  }

  function cardAlerts() {
    if (canViewPatientAlertInfos) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <CardHome
            header={{ text: 'Alertes' }}
            row={[
              {
                text: 'Nouvelles alertes :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStatePatientExportSummary.nbNewAlerts}`,
                  },
                ],
              },

              {
                text: 'Nouvelles transmissions :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStatePatientExportSummary.nbNewTransmissions}`,
                  },
                ],
              },

              {
                text: 'Patients déconnectés :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStatePatientExportSummary.nbDisconnected}`,
                  },
                ],
              },
            ]}
          />
        </Grid>
      )
    }
  }

  function cardTranmiters() {
    if (canViewTransmitteurInfo) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <CardHome
            header={{ text: 'Transmetteurs' }}
            row={[
              {
                text: 'Transmetteurs déconnectés :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStateTransmitterSummary.disconnect}`,
                  },
                ],
              },
              {
                text: 'Transmetteurs en cours de livraison :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStateTransmitterSummary.inDelivery}`,
                  },
                ],
              },
              {
                text: 'Aucune transmissions / planification :',
                link: '/alertes',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStateTransmitterSummary.noPlan}`,
                  },
                ],
              },
            ]}
          />
        </Grid>
      )
    }
  }

  function cardPatient() {
    if (canViewPatientInfos) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <CardHome
            header={{ text: 'Patients / Inscription' }}
            row={[
              // {
              //   text: 'Inscrire nouveau patient',
              //   link: '/patients',
              //   content: null,
              // },
              {
                text: 'dossiers à compléter :',
                link: '/patients',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStatePatientSummary.toComplete}`,
                  },
                ],
              },
              {
                text: 'Patients en attente de transmetteur :',
                link: '/patients',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStatePatientSummary.waitingTransmitter}`,
                  },
                ],
              },
            ]}
          />
        </Grid>
      )
    }
  }

  function cardBilling() {
    if (canViewPatientBillingInfo) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <CardHome
            header={{ text: 'Facturation' }}
            row={[
              {
                text: 'Patients à facturer ce mois-ci :',
                link: 'billing',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStateBillingSummary.monthBilling}`,
                  },
                ],
              },
              {
                text: 'Patients en retard de facturation :',
                link: 'billing',
                content: [
                  {
                    chip: true,
                    text: `${props.homeData.initStateBillingSummary.lateBilling}`,
                  },
                ],
              },
              {
                text: 'Calendrier de facturation',
                link: 'billing',
                content: null,
              },
            ]}
          />
        </Grid>
      )
    }
  }

  function cardPrescription() {
    if (canViewPatientPrescriptionInfo) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <CardHome
            header={{ text: 'Prescriptions' }}
            row={rowsPrescription()}
          />
        </Grid>
      )
    }
  }

  const canViewPatientInfos = useSelector(canViewPatientData)
  const canViewTransmitteurInfo = useSelector(canAddModifyDeleteTransmitter)
  const canViewPatientAlertInfos = useSelector(canViewPatientAlert)
  const canViewPatientBillingInfo = useSelector(canViewPatientBilling)
  const canViewPatientPrescriptionInfo = useSelector(canViewPatientPrescription)

  const CardHome = (props: cardProps) => {
    let iconHeader: any
    switch (props.header.text) {
      case 'Alertes':
        iconHeader = <AddAlertIcon />
        break
      case 'Patients / Inscription':
        iconHeader = <PersonIcon />
        break
      case 'Facturation':
        iconHeader = <SavingsIcon />
        break
      case 'Notifications':
        iconHeader = <ChatIcon />
        break
      case 'Transmetteurs':
        iconHeader = <RouterIcon />
        break
      case 'Statistiques':
        iconHeader = <CandlestickChartIcon />
        break
      case 'Prescriptions':
        iconHeader = <ReceiptIcon />
        break
      default:
        iconHeader = <HomeIcon />
    }

    return (
      <div className="home-card">
        <Paper elevation={2} className={classes.paperCard}>
          <List className={classes.listCard}>
            <ListItem
              alignItems="flex-start"
              className={
                props.disabled
                  ? 'home-card-header-disabled home-card-row'
                  : 'home-card-header home-card-row'
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              {iconHeader}
              <span className={classes.spanHeaderCard} />
              {props.header.text}
            </ListItem>

            {props.row.map((el, i) => {
              return (
                <ListItem
                  key={i}
                  disablePadding
                  onClick={
                    props.disabled
                      ? undefined
                      : () => handleClickItemListCard(el.link)
                  }
                  className={
                    props.disabled
                      ? 'home-card-row-disabled  home-card-content-disabled'
                      : 'home-card-rows home-card-content'
                  }
                >
                  <List
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      p: 0,
                    }}
                  >
                    <ListItem
                      className={
                        props.disabled
                          ? 'home-card-title-row-disabled'
                          : 'home-card-title-row'
                      }
                    >
                      {`${el.text}`}
                    </ListItem>

                    <ListItem
                      disablePadding
                      disableGutters
                      sx={{ display: 'flex', m: 0, p: 0 }}
                    >
                      {el.content?.map((el, i) => {
                        return (
                          <ListItem
                            disablePadding
                            alignItems="flex-start"
                            key={i}
                          >
                            <Typography
                              key={i}
                              className={
                                props.disabled
                                  ? 'home-card-content-disabled'
                                  : el.chip
                                    ? 'home-card-content-chip'
                                    : 'home-card-content'
                              }
                            >
                              {el.text}
                            </Typography>
                          </ListItem>
                        )
                      })}
                    </ListItem>

                    <ArrowForwardIosIcon
                      sx={{ m: 1, color: 'grey', width: '10px' }}
                    />
                  </List>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      </div>
    )
  }

  return (
    <Grid
      container
      sx={{ m: 0, display: 'flex', justifyContent: 'space-evenly' }}
    >
      {/* <div className="home-div"> */}

      <Loader text="Chargement des données" open={loading} />
      {cardAlerts()}
      {cardTranmiters()}
      {cardPatient()}
      {cardBilling()}
      {cardPrescription()}
    </Grid>
  )
}

export default HomeComponent
