import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  GET_TRANSMITTER_REFERENCES_SUCCESS,
  GET_PATIENT_SUCCESS,
  GET_COUNTRY_SUCCESS,
  GET_PROSTHESIS_GAMME_RANGE_SUCCESS,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENTS_SUMMARY_SUCCESS,
  POST_PATIENT_PRESCRIPTION_SUCCESS,
  POST_CRM_ENROLLMENT_SUCCESS,
  POST_CRM_SEND_SUCCESS,
  POST_PATIENT_PRESCRIPTION_FAILED,
} from '../constants'
import { toast } from 'react-toastify'

interface IState {
  patients: {
    data: IPatient[]
    totalPages: number
  }
  summary: ISummary | null
  patientInfo: IPatientInfos | null
  country: ICountry[] | null
  prosthesisGammeRange: IGammeRange | null
  transmitterReferences: ITransmitterReference[]
}

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IGammeRange {
  createdAt: string
  deletedAt: string
  id: number
  modifiedAt: string
  rangeList: any[]
  stringKey: string
  translation: string
}

export interface ISummary {
  active: number
  archived: number
  toComplete: number
  toValidate: number
  waitingTransmitter: number
}

export interface IPatientInfos {
  id: number
  gender: string
  patientName: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  countryId: number
  socialInsuranceNumber: string
  phone?: string
  historyComment?: string
  fev?: number
  treatmentComment?: string
  ald?: boolean
  cpamCenter: string
  phone1?: string
  phone2?: string
  phone3?: string
  implantDate?: string
  prosthesisGamme?: string
  prosthesisRange?: string
  prosthesisSerialNumber?: string
  maker?: string
  model?: string
  serialNumber?: string
  agreement?: boolean
  inWaitingTransmiter?: boolean
  billable?: boolean
  allowBilling?: boolean
  lastBillingDate?: string
  lastTransmissionDate?: string
  doctorIsSet?: boolean
  doctorName?: string
  doctorFirstName?: string
  lastStatus?: string
  centers?: ICenters[]
  patientDoctors?: IPatientDoctor[]
  billingList?: IBillingList[]
  historyList?: IHistoryList[]
  treatmentList?: ITreatmentList[]
  implantationList?: IImplantationList[]
  statusList?: IStatusList[]
  noteList?: INoteList[]
  alertList?: []
}

export interface IStatusList {
  id: number
  createdAt: string
  statusType: string
  comment: string
  userId: null
  user: string
  patientId: number
}

export interface ICenters {
  id: number
  companyName: string
  address: string
  zipCode: string
  city: string
  countryId: number
  phone: string
  type: string
  logoFileId: number
  country: {}
  centerDetails: {}
  logoStoredFile: string
}

export interface IAddPatientCenter {
  id: number
  createdAt: string
  modifiedAt: string
  deletedAt: string
  companyName: string
  address: string
  zipCode: string
  city: string
  countryId: number
  phone: string
  type: string
  logoField: string
}

export interface IPatientDoctor {
  id: number
  mail: string
  pwd: string
  userName: string
  firstName: string
  compId: number
  phone: string
  title: string
}

export interface IBillingList {
  id: number
  billingDate: string
  patientId: number
  billingDoctorId: number
  amount: number
}

export interface IHistoryList {
  id: number
  patientId: number
  historyTypeId: number
  historyType: {}
}

export interface ITreatmentList {
  id: number
  patientId: number
  treatmentTypeId: number
}

export interface IImplantationList {
  cardioAttent: IDoctor
  cardioImplant: IDoctor
  cardioBilling: IDoctor
  center: { company_name: string }
  createdAt: string
  id: number
  implantReasons: any[]
  implantationDate: string
  prosthesisObject: any
  transmitterAffectation: any
  prescription: any[]
  enrollment: any[]
}

export interface ITransmitterReference {
  createdAt: string
  id: number
  maker: any
  makerId: number
  modifiedAt: string
  referenceName: string
}

export interface INoteList {
  id: string
  userId: number
  patientId: number
  note: string
}

export interface IPatientWaiting {
  gender: string
  patientName: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  countryId: number
  socialInsuranceNumber: string
  phone: string
  historyComment: string
  fev: number
  treatmentComment: string
  ald: boolean
  cpamCenter: string
  phone1: string
  phone2: string
  phone3: string
  implantDate: string
  prosthesisGamme: string
  maker: string
  model: string
  serialNumber: string
  agreement: boolean
  inWaitingTransmiter: boolean
  billable: boolean
  allowBilling: boolean
  lastBillingDate: string
  lastTransmissionDate: string
  doctorIsSet: boolean
  doctorName: string
  doctorFirstName: string
  lastStatus: string
}

export interface ICreatePatient {
  [key: string]:
    | string
    | ICountry
    | null
    | undefined
    | IDoctor
    | boolean
    | number
    | any[]
  gender: string
  patientName: string

  patientNameUsual: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  country?: ICountry
  doctor: IDoctor | null
  socialInsuranceNumber: string
  phone?: string
  ald?: boolean
  cpamCenter: string
  phone1?: string
  phone2?: string
  phone3?: string
  doctorId?: number
}

export interface INote {
  note: string
  patientID: number
  userID: number
}

export interface IStatus {
  comment: string
  patientID: string
  statusType: string
  userID: number
}

export interface IPatientToComplete {
  gender: string
  patientName: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  countryId: number
  socialInsuranceNumber: string
  phone: string
  historyComment: string
  fev: number
  treatmentComment: string
  ald: boolean
  cpamCenter: string
  phone1: string
  phone2: string
  phone3: string
  implantDate: string
  prosthesisGamme: string
  maker: string
  model: string
  serialNumber: string
  agreement: boolean
  inWaitingTransmiter: boolean
  billable: boolean
  allowBilling: boolean
  lastBillingDate: string
  lastTransmissionDate: string
  doctorIsSet: boolean
  doctorName: string
  doctorFirstName: string
  lastStatus: string
}

export interface IPatientArchived {
  gender: string
  patientName: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  countryId: number
  socialInsuranceNumber: string
  phone: string
  historyComment: string
  fev: number
  treatmentComment: string
  ald: boolean
  cpamCenter: string
  phone1: string
  phone2: string
  phone3: string
  implantDate: string
  prosthesisGamme: string
  maker: string
  model: string
  serialNumber: string
  agreement: boolean
  inWaitingTransmiter: boolean
  billable: boolean
  allowBilling: boolean
  lastBillingDate: string
  lastTransmissionDate: string
  doctorIsSet: boolean
  doctorName: string
  doctorFirstName: string
  lastStatus: string
}

export interface IPatient {
  address: string
  agreement: boolean
  ald: boolean
  allowBilling: boolean
  billable: boolean
  birthDay: string
  city: string
  countryId: number
  cpamCenter: string
  createdAt: string
  deletedAt: string | null
  doctorFirstName: string
  doctorIsSet: boolean
  doctorName: string
  fev: number
  firstName: string
  gender: string
  historyComment: string | null
  id: number
  implantDate: string
  inWaitingTransmiter: Boolean
  lastBillingDate: string | null
  lastStatus: string
  lastTransmissionDate: string
  maker: string
  model: string
  modifiedAt: string
  patientName: string
  phone: string
  phone1: string
  phone2: string
  phone3: string
  prosthesisGamme: string
  serialNumber: string
  socialInsuranceNumber: string
  treatmentComment: string | null
  zipCode: string
}

export interface IDoctor {
  id: number
  createdAt: string
  modifiedAt: null | string
  deletedAt: null | string
  mail: null | string
  pwd: null | string
  userName: string
  firstName: string
  compId: null | string
  phone: string
  title: string
  company: null | string
  roles: [
    {
      id: number
      roleName: string
      description: string
    },
  ]
  addresses: [
    {
      id: number
      createdAt: string
      modifiedAt: null | string
      deletedAt: null | string
      street: string
      zipCode: null | string
      city: null | string
      countryId: number
      country: {
        id: number
        createdAt: string
        modifiedAt: null | string
        deletedAt: null | string
        countryName: string
        lang: string
        phonePrefix: number
      }
    },
  ]
  doctor: {
    id: number
    createdAt: string
    modifiedAt: null | string
    deletedAt: null | string
    rppsNumber: null | number
    cardioImplant: boolean
    billing: boolean
    cardioAttent: boolean
    doctorAttent: boolean
  }
}

export type PatientType =
  | 'ACTIVE'
  | 'ARCHIVED'
  | 'INWAINTING'
  | 'CHECKED'
  | 'TOCHECK'
  | 'BILLABLE'

export interface ICountry {
  countryName: string
  createdAt: string
  deletedAt: string | null
  id: 1 | 2
  lang: string
  modifiedAt: null | string
  phonePrefix: number
}

export interface IAddImplant {
  cardio_attent_id?: number
  cardio_billing_id?: number
  cardio_implant_id: number
  center_id: number
  implantation_date: string
  patient_id: number
}

export interface IUpdateImplant {
  cardio_attent_id?: IDoctor['id']
  cardio_billing_id?: IDoctor['id']
  cardio_implant_id: IDoctor['id']
  center_id: number
  implantation_date: string
  patient_id: number
  prosthesis_id: number
  id?: number
}

export interface IUpdateProsthesisId {
  prosthesis_id: number
  id: number
  patient_id: number
  center_id: number
  cardio_attent_id: number
  cardio_billing_id: number
  cardio_implant_id: number
}

export interface IAffectTransmitter {
  id?: number
  agreement: boolean
  centerId: number
  deliveredByCenter: boolean
  deliveryDate?: string
  deliveryNote: string
  firstTransmissionDate?: string
  patientId: number
  prosthesisId: number
  transmitterId: number
}

export interface IProsthesisObject {
  createdAt: string
  reference: {
    maker: any
    prosthesisGamme: any
    prosthesisRange: any
    serialNumber: string
  }
  id: number
  serialNumber: number
}

const initialState = {
  patients: {
    data: [],
    totalPages: 0,
    limit: 0,
  },
  summary: null,
  patientInfo: null,
  country: null,
  prosthesisGammeRange: null,
  prosthesisReferences: [],
  transmitterReferences: [],
  forceReloadPrescriptions: false,
} as IState

const patientsReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_PATIENT_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.patients.data = action.payload.data.data
      draft.patients.totalPages = action.payload.data.totalPages
      draft.patients.limit = action.payload.data.limit
      break
    case GET_PATIENTS_SUMMARY_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.summary = action.payload.data
      break
    case GET_PATIENT_BY_ID_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.patientInfo = action.payload.data
      break
    case GET_COUNTRY_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.country = action.payload.data
      break
    case GET_PROSTHESIS_GAMME_RANGE_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.prosthesisGammeRange = action.payload.data
      break
    case GET_TRANSMITTER_REFERENCES_SUCCESS:
      draft.forceReloadPrescriptions = false
      draft.transmitterReferences = action.payload.data
      break
    case POST_PATIENT_PRESCRIPTION_SUCCESS:
      draft.patientInfo?.implantationList[0].prescription.push(
        action.payload.data.prescription,
      )
      draft.forceReloadPrescriptions = true
      toast.success('Prescription initiée')
      break
    case POST_PATIENT_PRESCRIPTION_FAILED:
      draft.forceReloadPrescriptions = true
      break
    case POST_CRM_ENROLLMENT_SUCCESS:
      draft.forceReloadPrescriptions = false
      if (draft.patientInfo && action.payload.data instanceof Array) {
        draft.patientInfo.implantationList[0].enrollment = action.payload.data
      }

      toast.success('Documents générés')
      break
    case POST_CRM_SEND_SUCCESS:
      draft.forceReloadPrescriptions = false
      if (draft.patientInfo?.implantationList[0]) {
        draft.patientInfo.implantationList[0].enrollmentRequestSend = true
      }
      toast.success('Documents envoyés')
      break

    default:
      return draft
  }
})

export default patientsReducer
