import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  Box,
  ClassNameMap,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Button,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { DoctorType } from '../../../doctors/reducers'
import Loader from '../../../common/Loader'
import {
  ICenters,
  IDoctor,
  IImplantationList,
  IUpdateImplant,
} from '../../reducers'
import SearchDoctorModal from '../SearchDoctorModal'
import dateformat from 'dateformat'

interface IProps {
  isOpen: boolean
  classes: ClassNameMap<
    'paperCard' | 'listCard' | 'spanHeaderCard' | 'newNotePaper'
  >
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateImplant: (infos: IUpdateImplant) => Promise<unknown>
  setDoctorModal: React.Dispatch<React.SetStateAction<boolean>>
  patientId: number
  center: ICenters
  getDoctors: (
    size: number,
    page: number,
    doctorType: string,
    search: string,
    companyId?: number,
  ) => Promise<unknown>
  implantation: IImplantationList
  deleteImplant: (patientId: number, implantId: number) => Promise<unknown>
  companyId?: number
}

const ModalModifyImplant = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [implantDate, setImplantDate] = useState('')
  const [cardioImplant, setCardioImplant] = useState<IDoctor>(
    props.implantation.cardioImplant,
  )
  const [cardioAttent, setCardioAttent] = useState<IDoctor | undefined>()
  const [cardioBilling, setCardioBilling] = useState<IDoctor | undefined>()
  const [doctorType, setDoctorType] = useState<DoctorType>('Implant')
  const [isSearchDocOpen, setIsSearchDocOpen] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  useEffect(() => {
    setImplantDate(
      dateformat(props.implantation.implantationDate, 'yyyy-mm-dd'),
    )
    setCardioAttent(
      props.implantation.cardioAttent.hasOwnProperty('id')
        ? props.implantation.cardioAttent
        : undefined,
    )
    setCardioBilling(
      props.implantation.cardioBilling.hasOwnProperty('id')
        ? props.implantation.cardioBilling
        : undefined,
    )
    setCardioImplant(props.implantation.cardioImplant)
  }, [props.implantation])

  const InvoicingField = () => {
    return <span style={{ color: 'limegreen', fontWeight: 'bold' }}>€</span>
  }

  const RequiredField = () => {
    return <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
  }

  const openSearchDoctor = (type: DoctorType) => {
    setDoctorType(type)
    setIsSearchDocOpen(true)
  }

  const handleDoctorSelection = (doctor: any) => {
    switch (doctorType) {
      case 'Cardiolog':
        setCardioAttent(doctor.row)
        break
      case 'Billing':
        setCardioBilling(doctor.row)
        break
      case 'Implant':
        setCardioImplant(doctor.row)
        break
      default:
        return
    }
    setIsSearchDocOpen(false)
  }

  const handleSubmit = async () => {
    const infos = {
      cardio_attent_id: cardioAttent ? cardioAttent.id : undefined,
      cardio_implant_id: cardioImplant.id,
      cardio_billing_id: cardioBilling ? cardioBilling.id : undefined,
      center_id: props.center.id,
      patient_id: props.patientId,
      implantation_date: new Date(implantDate).toISOString(),
      id: props.implantation.id,
      prosthesis_id: props.implantation.prosthesisObject.id,
    }

    if (cardioImplant && implantDate !== '') {
      setIsLoading(true)
      console.log(`modal will update ${JSON.stringify(infos)}`)
      try {
        await props.updateImplant(infos)
        toast.success('Implantation modifiée')
        props.setIsOpen(false)
      } catch {
        toast.error("Erreur lors de la modification de l'implantation")
      } finally {
        setIsLoading(false)
      }
    } else
      toast.error(
        <>
          Veuillez remplir les champs obligatoires: <RequiredField />
        </>,
      )
  }

  const handleDeleteImplant = () => {
    setIsLoading(true)
    try {
      props.deleteImplant(props.patientId, props.implantation.id)
      setModalDelete(false)
      props.setIsOpen(false)
    } catch {
      toast.error("Erreur lors de la suppression de l'implantation")
    } finally {
      setIsLoading(false)
    }
  }

  const ModalDelete = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={modalDelete}
        onClose={() => setModalDelete(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              color: 'white',
              fontWeight: '700',
              fontSize: '24px',
            }}
          >
            {'Confirmer'}
            <IconButton
              sx={{ position: 'absolute', right: '20px' }}
              onClick={() => setModalDelete(false)}
            >
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ paddingTop: '20px' }}>
              Êtes-vous sûr de vouloir supprimer l'implantation ?
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '30px',
                height: '30px',
              }}
            >
              <Button
                className="group-button"
                onClick={() => setModalDelete(false)}
              >
                Retour
              </Button>
              <Button
                className="group-button-success"
                onClick={handleDeleteImplant}
              >
                Confirmer
              </Button>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <SearchDoctorModal
        isOpen={isSearchDocOpen}
        setIsOpen={setIsSearchDocOpen}
        doctorType={doctorType}
        handleDoctorSelection={handleDoctorSelection}
        getDoctors={props.getDoctors}
        companyId={props.companyId}
      />
      <Loader text="Enregistrement des modifications" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {"Modifier l'implantation"}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <FormControl sx={{ height: '100%', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Date d'implantation <RequiredField /> :
              </span>
              <TextField
                // InputProps={{ inputProps: { max: new Date().toISOString().slice(0, 10) } }}
                margin="dense"
                type="date"
                variant="standard"
                value={implantDate}
                onChange={(event) => setImplantDate(event.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue implanteur <RequiredField /> :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={`${cardioImplant.firstName} ${cardioImplant.userName}`}
                onClick={() => openSearchDoctor('Implant')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue traitant :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={
                  cardioAttent
                    ? `${cardioAttent.firstName} ${cardioAttent.userName}`
                    : props.implantation.cardioAttent.hasOwnProperty('id')
                      ? `${props.implantation.cardioAttent.firstName} ${props.implantation.cardioAttent.userName}`
                      : ''
                }
                onClick={() => openSearchDoctor('Cardiolog')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue facturant la télésurveillance <InvoicingField /> :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={
                  cardioBilling
                    ? `${cardioBilling.firstName} ${cardioBilling.userName}`
                    : props.implantation.cardioBilling.hasOwnProperty('id')
                      ? `${props.implantation.cardioBilling.firstName} ${props.implantation.cardioBilling.userName}`
                      : ''
                }
                onClick={() => openSearchDoctor('Billing')}
              />
            </div>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '15px',
            }}
          >
            <IconButton onClick={() => setModalDelete(true)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
      <ModalDelete />
    </Dialog>
  )
}

export default ModalModifyImplant
