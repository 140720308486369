import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  NativeSelect,
  TextField,
  Box,
  Button,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { IUpdatePatientProsthesis } from '../../../../modules/protheses/reducers'
import { RootState } from 'src/store'
import Loader from '../../../common/Loader'
import { IDoctor, IImplantationList } from '../../reducers'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  getProsthesisReferences: () => Promise<unknown>
  selectedImplantation: IImplantationList | null
  updateProsthesis: (
    infos: IUpdatePatientProsthesis,
    method: 'POST' | 'PATCH',
    implantationId: number,
    patientId: number,
    cardioAttent: IDoctor,
    cardioBilling: IDoctor,
    cardioImplant: IDoctor,
  ) => Promise<unknown>
  deleteProsthesis: (id: number) => Promise<unknown>
  patientId: number
}

type GammeType =
  | 'prosthesis.gamme.defib.cardiac'
  | 'prosthesis.gamme.monit.implant'
  | 'prosthesis.gamme.stim.cardiac'

type RangeType =
  | 'not-defined'
  | 'prosthesis.range.simple.chamber'
  | 'prosthesis.range.dual.chamber'
  | 'prosthesis.range.triple.chamber'
  | 'prosthesis.range.under.skin'
  | 'prosthesis.range.without.lead'

const ModalModifyProsthesis = (props: IProps) => {
  const prosthesisReferences = useSelector(
    (state: RootState) => state.prosthesisReducer.prosthesisReferences,
  )
  const [isLoading, setIsLoading] = useState(false) //eslint-disable-line
  const [prosthesisType, setProsthesisType] = useState<GammeType>()
  const [makers, setMakers] = useState<Array<string>>([])
  const [references, setReferences] = useState<any[]>(prosthesisReferences)
  const [selectedMaker, setSelectedMaker] = useState<string>('')
  const [selectedRange, setSelectedRange] = useState<RangeType>('not-defined')
  const [selectedProsthesisId, setSelectedProsthesisId] = useState<number>()
  const [serialNumber, setSerialNumber] = useState('')
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

  // get refereneces and makers
  useEffect(() => {
    getMakers()
    props
      .getProsthesisReferences()
      .catch(() =>
        toast.error('Impossible de récupérer les références de prothèses'),
      )
  }, []) //eslint-disable-line

  useEffect(() => {
    setReferences(prosthesisReferences)
  }, [prosthesisReferences])

  //set values from patientInfos
  useEffect(() => {
    setProsthesisType(
      props.selectedImplantation?.prosthesisObject?.reference?.prosthesisGamme
        ?.string_key || undefined,
    )
    setSelectedMaker(
      props.selectedImplantation?.prosthesisObject?.reference?.maker?.company
        ?.company_name || '',
    )
    setSelectedRange(
      props.selectedImplantation?.prosthesisObject?.reference?.prosthesisRange
        ?.string_key || 'not-defined',
    )
    setSerialNumber(
      props.selectedImplantation?.prosthesisObject?.serialNumber?.toString() ||
        '',
    )
    setSelectedProsthesisId(
      props.selectedImplantation?.prosthesisObject?.reference?.maker?.id ||
        undefined,
    )
  }, [props.selectedImplantation?.prosthesisObject]) //eslint-disable-line

  // references filter
  useEffect(() => {
    if (prosthesisType === 'prosthesis.gamme.monit.implant')
      setSelectedRange('not-defined')
    if (
      prosthesisType &&
      selectedMaker !== '' &&
      selectedRange !== 'not-defined'
    )
      setReferences([
        ...prosthesisReferences.filter(
          (ref: any) =>
            ref.prosthesisGamme.stringKey === prosthesisType &&
            ref.maker.companyName === selectedMaker &&
            ref.prosthesisRange.stringKey === selectedRange,
        ),
      ])
    else if (prosthesisType && selectedMaker !== '')
      setReferences([
        ...prosthesisReferences.filter(
          (ref: any) =>
            ref.prosthesisGamme.stringKey === prosthesisType &&
            ref.maker.companyName === selectedMaker,
        ),
      ])
    else if (prosthesisType)
      setReferences([
        ...prosthesisReferences.filter(
          (ref: any) => ref.prosthesisGamme.stringKey === prosthesisType,
        ),
      ])
  }, [prosthesisType, selectedMaker, selectedRange, prosthesisReferences]) //eslint-disable-line

  const getMakers = () => {
    if (prosthesisReferences) {
      // space before "Non défini" to insure first place after sort
      let tempPR: string[] = [' Non défini']
      prosthesisReferences.forEach((proRef: any) => {
        if (!tempPR.includes(proRef.maker.companyName))
          tempPR.push(proRef.maker.companyName)
      })
      setMakers(tempPR)
    }
  }

  const RequiredField = () => {
    return <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
  }

  const handleSubmit = async () => {
    console.log(selectedProsthesisId, serialNumber, props.selectedImplantation)

    if (
      selectedProsthesisId &&
      serialNumber.length > 0 &&
      props.selectedImplantation
    ) {
      setIsLoading(true)
      try {
        if (props.selectedImplantation.prosthesisObject?.id) {
          await props.updateProsthesis(
            {
              serialNumber,
              referenceId: selectedProsthesisId,
              id: props.selectedImplantation.prosthesisObject.id,
            },
            'PATCH',
            props.selectedImplantation.id,
            props.patientId,
            props.selectedImplantation.cardioAttent,
            props.selectedImplantation.cardioBilling,
            props.selectedImplantation.cardioImplant,
          )
        } else {
          await props.updateProsthesis(
            { serialNumber, referenceId: selectedProsthesisId },
            'POST',
            props.selectedImplantation.id,
            props.patientId,
            props.selectedImplantation.cardioAttent,
            props.selectedImplantation.cardioBilling,
            props.selectedImplantation.cardioImplant,
          )
          toast.success('Modifications enregistrées')
          props.setIsOpen(false)
        }
      } catch {
        toast.error("Erreur lors de l'enregistrement des modifications")
      } finally {
        setIsLoading(false)
      }
    } else
      toast.error(
        <>
          Veuillez compléter les informations requises <RequiredField />
        </>,
      )
  }

  const handleDelete = async () => {
    if (props.selectedImplantation?.prosthesisObject) {
      setIsLoading(true)
      try {
        await props.deleteProsthesis(
          props.selectedImplantation.prosthesisObject.id,
        )
        toast.success('Prothèse supprimée')
        setModalDeleteOpen(false)
        props.setIsOpen(false)
      } catch {
        toast.error('Erreur lors de la suppression de la prothèse')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const ModalDelete = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              color: 'white',
              fontWeight: '700',
              fontSize: '24px',
            }}
          >
            {'Confirmer'}
            <IconButton
              sx={{ position: 'absolute', right: '20px' }}
              onClick={() => setModalDeleteOpen(false)}
            >
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ paddingTop: '20px' }}>
              Êtes-vous sûr de vouloir supprimer la prothèse ?
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '30px',
                height: '30px',
              }}
            >
              <Button
                className="group-button"
                onClick={() => setModalDeleteOpen(false)}
              >
                Retour
              </Button>
              <Button className="group-button-success" onClick={handleDelete}>
                Confirmer
              </Button>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement des modifications" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {props.selectedImplantation?.prosthesisObject
            ? 'Modifier la prothèse'
            : 'Ajouter une prothèse'}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div style={{ marginTop: '20px' }}>
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Type de prothèse <RequiredField /> :
            </span>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={prosthesisType ? prosthesisType : ''}
              onChange={(_, value) => {
                setSelectedProsthesisId(undefined)
                setProsthesisType(value as GammeType)
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
                marginTop: '10px',
              }}
            >
              <FormControlLabel
                value="prosthesis.gamme.defib.cardiac"
                checked={prosthesisType === 'prosthesis.gamme.defib.cardiac'}
                control={
                  <Radio
                    sx={{
                      color:
                        prosthesisType === 'prosthesis.gamme.defib.cardiac'
                          ? '#84c2ce !important'
                          : '',
                    }}
                  />
                }
                label="Défibrillateur cardiaque"
              />
              <FormControlLabel
                value="prosthesis.gamme.monit.implant"
                checked={prosthesisType === 'prosthesis.gamme.monit.implant'}
                control={
                  <Radio
                    sx={{
                      color:
                        prosthesisType === 'prosthesis.gamme.monit.implant'
                          ? '#84c2ce !important'
                          : '',
                    }}
                  />
                }
                label="Moniteur implantable"
              />
              <FormControlLabel
                value="prosthesis.gamme.stim.cardiac"
                checked={prosthesisType === 'prosthesis.gamme.stim.cardiac'}
                control={
                  <Radio
                    sx={{
                      color:
                        prosthesisType === 'prosthesis.gamme.stim.cardiac'
                          ? '#84c2ce !important'
                          : '',
                    }}
                  />
                }
                label="Stimulateur cardiaque"
              />
            </RadioGroup>
          </div>
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Marque de la prothèse <RequiredField /> :
            </span>
            <NativeSelect
              value={selectedMaker}
              onChange={(event) => {
                setSelectedProsthesisId(undefined)
                setSelectedMaker(event.target.value)
              }}
            >
              {[...makers].sort().map((maker) => (
                <option key={'maker' + maker} value={maker}>
                  {maker}
                </option>
              ))}
            </NativeSelect>
          </div>
          {selectedMaker !== '' &&
            prosthesisType !== 'prosthesis.gamme.monit.implant' && (
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                  Segment :
                </span>
                <NativeSelect
                  value={selectedRange}
                  onChange={(event) => {
                    setSelectedProsthesisId(undefined)
                    setSelectedRange(event.target.value as RangeType)
                  }}
                >
                  <option value={'not-defined'}>Non défini</option>
                  <option value="prosthesis.range.simple.chamber">
                    Simple chambre
                  </option>
                  <option value="prosthesis.range.dual.chamber">
                    Double chambre
                  </option>
                  <option value="prosthesis.range.triple.chamber">
                    Triple chambre
                  </option>
                  {prosthesisType !== 'prosthesis.gamme.stim.cardiac' && (
                    <option value="prosthesis.range.under.skin">
                      Sous-cutané
                    </option>
                  )}
                  {prosthesisType !== 'prosthesis.gamme.defib.cardiac' && (
                    <option value="prosthesis.range.without.lead">
                      Sans sonde
                    </option>
                  )}
                </NativeSelect>
              </div>
            )}
          {selectedMaker !== '' && (
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Modèle de la prothèse <RequiredField /> :
              </span>

              {references.length > 0 ? (
                <NativeSelect
                  value={selectedProsthesisId}
                  onChange={(event) =>
                    setSelectedProsthesisId(parseInt(event.target.value))
                  }
                >
                  <option value={'not-defined'}>Non défini</option>
                  {[...references]
                    .sort((a, b) =>
                      a.referenceName?.localeCompare(b.referenceName),
                    )
                    .map((reference) => (
                      <option key={'ref' + reference.id} value={reference.id}>
                        {reference.referenceName}
                        {reference.prosthesisModel
                          ? ' - ' + reference.prosthesisModel
                          : ''}
                      </option>
                    ))}
                </NativeSelect>
              ) : (
                <span>Aucune référence</span>
              )}
            </div>
          )}
          <div
            style={{
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>
              Numéro de série <RequiredField /> :
            </span>
            <TextField
              sx={{ marginLeft: '10px', width: '50%' }}
              value={serialNumber}
              variant="standard"
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <IconButton onClick={() => setModalDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
      <ModalDelete />
    </Dialog>
  )
}

export default ModalModifyProsthesis
